blockquote {
	margin-top: 30px;
	margin-bottom: 30px;

	&:before {
		content: '';
		width: 12px;
		height: 90%;
		position: absolute;
		background-color: orange;
		left: -12px;
		top: 5%; }

	p {
		font-size: 1.4rem;
		font-style: italic;
		quotes: '\0022' '\0022';
		display: inline;

		&:before {
			content: open-quote; }
		&:after {
			content: close-quote; } }

	cite {
		display: inline-block;
		margin-left: 10px;
		font-size: 0.8rem;

		&:before {
			content: '-'; } }

	footer {
		background-color: transparent;
		padding: 0;
		text-align: left;
		margin-top: 15px;

		a {
			font-family: $fontDefault;
			font-style: italic;
			font-size: 0.8rem; }

		&:before {
			content: ''; } } }
