$theme: baptistOnline;

/***** Colors *****/
$colorBrandPrimary: #005699;
$colorBrandLight: #2970c2;
$colorBrandLightAccent: #297CBB;
$colorBrandLightest: #67CEF2;
$colorBrandDark: #01305E;
$colorBrandPastel: #2C6693;
$colorCancerCenterPrimary: $colorBrandLightAccent;
$colorBrandLink: #5B88E1;
$colorMobileMenuTop: #F6FCFE;
$colorMobileMenuTopBorder: #68CEF3;

$colorAccent: #FEBE10;

$colorNeutralDarkest: #3D3D3D;
$colorNeutralDarkerStill: #5C5C5C;
$colorNeutralDarker: #979797;
$colorNeutralDark: #CDCDCD;
$colorNeutralLight: #E9E9E9;
$colorNeutralLighter: #F5F5F5;
$colorNeutralLightest: #FFFFFF;

$colorBackgroundAccentLight: #F5F6F7;

$colorFontDefault: #4A4A4A;
$colorFontLight: #FFFFFF;

$colorDarkerNav: hsla(0, 0%, 0%, 0.15);

@if $theme == baptistOnline {
	$colorDarkerNav: hsla(0, 0%, 0%, 0.15); }
@else if $theme == cancerCenter {
	$colorDarkerNav: #D7D7D7; }
@else if $theme == medicalGroup {
	$colorDarkerNav: hsla(0, 0%, 0%, 0.07); }
@else if $theme == brainSpineNetwork {
	$colorDarkerNav: hsla(0, 0%, 0%, 0.07); }

/***** Fonts *****/
$fontDefault: Georgia, serif;
$fontAccent: "Gotham SSm A", "Gotham SSm B", sans-serif;
$fontHeading: "Sentinel SSm A", "Sentinel SSm B", serif;

/***** Breakpoints *****/
$bpXSmall: 420px;
$bpSmall: 767px;
$bpMed: 992px;
$bpLarge: 1200px;

/***** Miscellaneous *****/
$boxShadow: 0 2px 4px hsla(0, 0%, 0%, 0.18);
$boxShadowHeavy: 0 2px 4px hsla(0, 0%, 0%, 0.5);
$rightInsetBoxShadow: -12px 0px 10px -10px rgba(0, 0, 0, 0.5) inset;
$buttonBoxShadow: 0 2px 4px hsla(0, 0%, 0%, 0.5);
$textShadow: 0 2px 4px hsla(0, 0%, 0%, 0.5);
$largeShadow: 0 2px 11px hsla(0, 0%, 0%, 0.24);
$largeInsetShadow: -2px 2px 11px hsla(0, 0%, 0%, 0.24) inset;
$formFieldBorderRadius: 3px;
$mobileMenuBoxShadow: 0 0 100px rgba(0, 0, 0, 0.6);
$mobileMenuOverlay: 0 0 0 9999px transparentize($colorBrandPrimary, 0.45);
