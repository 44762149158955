@import "abstracts/vars";
.navigation.navigation-main {
	.level1.active {
		background-color: white;

		.field-navigationtitle {
			a {
				color: $colorBrandPrimary;
				&:after {
					background-image: url("/-/media/Project/Baptist/Baptist/United-States/BMH-Overrides/images/down-arrow.svg"); } } } } }
